<template>
  <div
    :data-orion-id="`smilepay-ui-card-content-${optionId}`"
    :class="[
      'smilepay-ui-card-content',
      'card card-content-padding',
      'justify-center',
      'mt-5',
      {
        'payment-selected': selected,
        'has-border': selected
      },
    ]"
  >
    <smile-media-card
      data-orion-id="smilepay-ui-smile-card"
      class="smilepay-ui-smile-card"
      contentContainerCustomClass="smilepay-ui-card-first-child pt-5 pb-5"
      @click.native="selectedPaymentPlan"
    >
      <template v-slot:card-content>
        <div class="is-flex is-justify-content-space-between mb-14">
          <SmilePayLabel
            v-if="showLabel"
            :label-config="labelConfig"
            class="smilepay-label"
          />
          <smile-radio
            class="smilepay-radio"
            :data-orion-id="`smilepay-ui-payment-option-type-${optionId}`"
            :native-value="true"
            :value="selected"
          />
        </div>
        <div class="smilepay-title-price-wrapper is-flex is-justify-content-space-between mt-8">
          <div class="smilepay-title-text">
            {{ title }}<sup v-if="financeTerm" class="tm-superscript">TM</sup>
          </div>
          <div
            class="price-container"
            :data-orion-id="`smilepay-ui-payment-option-price-${optionId}`"
          >
            <div
              v-if="isDiscountValid"
              :data-orion-id="`smilepay-ui-payment-option-discount-price-${optionId}`"
            >
              <b>{{ discountedPriceText }}</b>
            </div>
            <div :class="{ 'discount-text': isDiscountValid, 'bold-text': !isDiscountValid }">
              {{ priceText }}
            </div>
          </div>
        </div>
        <hr class="smilepay-black-line" />
        <p v-if="showLabel" class="smilepay-payment-text">{{ paymentText }}</p>
        <div class="smilepay-description-content">
          <ul>
            <li
              class="smilepay-description-item"
              v-for="description in paymentDescriptions"
              :key="description"
              :data-orion-id="`smilepay-ui-payment-option-description-${optionId}`"
            >
              <smile-icon icon="fe fe-check-circle" type="is-primary" />
              <p class="smilepay-description-item-text" v-html="description"></p>
            </li>
          </ul>
        </div>
      </template>
    </smile-media-card>
  </div>
</template>

<script>
import { SmileMediaCard, SmileRadio, SmileIcon } from 'sdc-component-library'
import { countryCodesEnum, paymentOptionsTitlesEnum } from '@/utils/constants'
import { formatEventPrice, formatPrice } from '@/utils/formatter'
import { v4 as uuidv4 } from 'uuid'
import SmilePayLabel from './SmilePayLabel.vue'

export default {
  name: 'PaymentOption',
  components: { SmileMediaCard, SmileRadio, SmileIcon, SmilePayLabel },
  props: {
    title: {
      required: true,
      type: String,
    },
    price: {
      required: true,
      type: Number,
    },
    productSKU: {
      required: true,
      type: String,
    },
    currency: {
      required: true,
      type: String,
    },
    country: {
      required: true,
      type: String,
    },
    discount: {
      required: false,
      type: Number,
    },
    paymentDescriptions: {
      required: true,
      type: Array,
    },
    targetDomain: {
      type: String,
      default: '/',
    },
    financeTerm: {
      type: Object,
    },
    discountedOption: {
      type: Object,
    },
    region: String,
    selected: Boolean,
    labelConfig: {
      type: Object,
      default: () => ({}),
    },
    paymentText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      annualPercentageRate: 10,
      businessEntity: this.country,
      paymentPlan: this.title.replace(/ /g, '').toUpperCase(),
      countryCodesEnum,
      paymentOptionsTitlesEnum,
    }
  },
  mounted() {
    this.$emit("mounted")
  },
  methods: {
    preparePaymentPlanData() {
      const isDiscountApplied = this.discount
      const financeTerm = this.discountedOption || this.financeTerm
      const creditProduct = financeTerm?.credit_product

      const eventMessage = {
        eventType: 'SelectedPaymentPlanEvent',
        eventId: uuidv4(),
        amount: isDiscountApplied
          ? this.price - this.discount
          : this.price,
        currency: this.currency,
        businessEntity: this.country,
        paymentPlan: this.paymentPlan,
        months: this.financeTerm ? financeTerm.months : undefined,
        downPayment: this.financeTerm
          ? formatEventPrice(creditProduct.down_payment * 100)
          : undefined,
        totalPayment: this.financeTerm
          ? formatEventPrice(financeTerm.total_payment * 100)
          : undefined,
        monthlyPayment: this.financeTerm
          ? formatEventPrice(financeTerm.monthly_payment * 100)
          : undefined,
        creditProductId: this.financeTerm ? creditProduct.product_id : undefined,
        financingProvider: this.financeTerm ? creditProduct.provider_name : undefined,
        supportedPaymentMethods: this.financeTerm
          ? creditProduct.supported_payment_methods
          : undefined,
        originalPrices: isDiscountApplied ? {
          amount: this.price,
          totalPayment: this.financeTerm
            ? formatEventPrice(this.financeTerm.total_payment * 100)
            : undefined,
          monthlyPayment: this.financeTerm
            ? formatEventPrice(this.financeTerm.monthly_payment * 100)
            : undefined,
        } : undefined,
      }

      return eventMessage;
    },
    selectedPaymentPlan() {
      // [this.$vnode.key] Key value passed from the main component
      this.$emit('payment-option-selected', this.$vnode.key)

      if (window.parent) {
        const data = this.preparePaymentPlanData()
        const eventMessage = {
          eventType: 'SelectedPaymentPlanEvent',
          eventId: uuidv4(),
          ...data,
        };
        window.parent.postMessage(eventMessage, this.targetDomain)
      }
    },
  },
  computed: {
    priceFormatted() {
      return formatPrice(this.price / 100, this.currency)
    },
    monthlyPriceFormatted() {
      return formatPrice(this.financeTerm?.monthly_payment, this.currency, true)
    },
    dicountedPriceFormatted() {
      return formatPrice((this.price - this.discount) / 100, this.currency)
    },
    discountedMonthlyPriceFormatted() {
      return formatPrice(this.discountedOption?.monthly_payment, this.currency, true)
    },
    optionId() {
      return this.$vnode.key || 1
    },
    isDiscountValid() {
      return this.discount && Number.isInteger(this.discount) && this.discount > 0
    },
    priceText() {
      switch (this.title) {
        case paymentOptionsTitlesEnum.SINGLE_PAY:
          return this.isDiscountValid ? this.priceFormatted : `${this.priceFormatted} total`
        case paymentOptionsTitlesEnum.SMILE_PAY:
          return `${this.monthlyPriceFormatted}/month`
        default:
          return ''
      }
    },
    discountedPriceText() {
      switch (this.title) {
        case paymentOptionsTitlesEnum.SINGLE_PAY:
          return `${this.dicountedPriceFormatted} total`
        case paymentOptionsTitlesEnum.SMILE_PAY:
          return `${this.discountedMonthlyPriceFormatted}/month`
        default:
          return ''
      }
    },
    showLabel() {
      return this.title !== paymentOptionsTitlesEnum.PURCHASE_LATER
    },
  },
  watch: {
    discountedOption: {
      handler: function() {
        if (!this.selected) return;

        if (window.parent) {
          const data = this.preparePaymentPlanData()
          const eventMessage = {
            eventType: 'SelectedPaymentPlanEvent',
            eventId: uuidv4(),
            ...data,
          }
          window.parent.postMessage(eventMessage, this.targetDomain)
        }
      },
      deep: true,
    },
  },
}
</script>

<style>
.smilepay-ui-card-first-child > div:first-child {
  display: inline-block !important;
  width: 100%;
}
</style>

<style scoped>
.smilepay-title-text {
  font-weight: bolder;
  font-size: 24px;
  color: #5700ff;
}
.payment-selected {
  border-color: #5700ff;
}
.smilepay-ui-card-content {
  font-size: 14px;
  border-radius: 10px;
}
.smilepay-ui-smile-card {
  cursor: pointer;
}
.smilepay-label {
  position: absolute;
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.smilepay-radio {
  position: absolute;
  right: 4px !important;
  top: 25px !important;
}
.smilepay-title-price-wrapper {
  align-items: center;
  align-content: center;
}
.tm-superscript {
  font-size: 13px;
  font-weight: bold;
}
.smilepay-black-line {
  border-color: black;
  margin-top: 15px;
  margin-bottom: 15px;
}
.smilepay-payment-text {
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-top: 18px;
  margin-bottom: 18px;
}
.price-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: black;
  font-size: 24px;
}
.discount-text {
  text-decoration: line-through;
  color: gray;
  font-size: 14px;
  margin-left: 8px;
  margin-bottom: 5px;
}
.smilepay-description-content ul {
  list-style-type: none;
  margin-left: 0;
  text-align: left;
  justify-content: center;
  align-items: center;
}
.smilepay-description-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.smilepay-description-item-text {
  margin-left: 10px;
}
.bold-text {
  font-weight: bold;
}
/* Mobile Styles */
@media (max-width: 600px) {
  .smilepay-title-price-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }

  .price-container {
    margin-top: 8px;
  }
}
</style>
