<template>
  <SmilePayUI
    :price="price"
    :productSKU="productSKU"
    :currency="currency"
    :country="country"
    :region="region"
    :targetDomain="targetDomain"
    :financingPrice="financingPrice"
    :financingProductSKU="financingProductSKU"
    :showDeferredPayment="showDeferredPayment"
    :env="getSmilePayEnv"
    :defaultOption="defaultOption"
    :defaultSubOption="defaultSubOption"
    :discount="discount"
  />
</template>

<script>
import SmilePayUI from '../components/SmilePayUI.vue'
import { ENV } from "@/utils/constants";

export default {
  name: 'SmilePayUiView',
  props: [
    'price',
    'productSKU',
    'currency','country',
    'region',
    'targetDomain',
    'financingPrice',
    'financingProductSKU',
    'showDeferredPayment',
    'env',
    'defaultOption',
    'defaultSubOption',
    'discount',
  ],
  components: { SmilePayUI },
  computed: {
    getSmilePayEnv() {
      return ENV;
    },
  },
}
</script>
