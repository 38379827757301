var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("SmilePayUI", {
    attrs: {
      price: _vm.price,
      productSKU: _vm.productSKU,
      currency: _vm.currency,
      country: _vm.country,
      region: _vm.region,
      targetDomain: _vm.targetDomain,
      financingPrice: _vm.financingPrice,
      financingProductSKU: _vm.financingProductSKU,
      showDeferredPayment: _vm.showDeferredPayment,
      env: _vm.getSmilePayEnv,
      defaultOption: _vm.defaultOption,
      defaultSubOption: _vm.defaultSubOption,
      discount: _vm.discount,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }