
import Vue from 'vue';
import VueRouter from 'vue-router'
import SmilePayUiView from "../views/SmilePayUiView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'SmilePayUiView',
        component: SmilePayUiView,
        props: (route) => (
            {
                productSKU: route.query.productSKU,
                price: Number(route.query.price),
                financingProductSKU: route.query.financingProductSKU,
                financingPrice: Number(route.query.financingPrice),
                currency: route.query.currency,
                country: route.query.country,
                region: route.query.region,
                showDeferredPayment: String(route.query.showDeferredPayment).toLowerCase() === 'true',
                targetDomain: route.query.targetDomain,
                env: route.query.env,
                defaultOption: route.query.defaultOption,
                defaultSubOption: route.query.defaultSubOption,
                discount: Number(route.query.discount),
            }),
    },

];
const router = new VueRouter({
    mode: "history",
    routes,
});

export default router;
