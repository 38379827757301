/* eslint-disable */
import Vue from 'vue';
import router from './router';
import App from './App.vue';
import { datadogLogs } from '@datadog/browser-logs';
import ComponentLibrary from 'sdc-component-library';

import 'sdc-component-library/dist/sdc.css';

Vue.use(ComponentLibrary);

Vue.config.productionTip = false;

if (process.env.VUE_APP_DD_ENV !== 'dev') {
  datadogLogs.init({
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    service: process.env.VUE_APP_DATADOG_SERVICE,
    site: process.env.VUE_APP_DATADOG_SITE,
    version: process.env.PACKAGE_VERSION,
    env: process.env.VUE_APP_ENV,
    forwardErrorsToLogs: true
  });

  console.log('DD logging initialized.');
}

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
