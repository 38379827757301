<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
}
</script>

<style>
#app {
  font-family: 'Sofia Pro';
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  color: #212529;
}
</style>
