var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showComponent
    ? _c(
        "div",
        {
          staticClass: "container",
          attrs: {
            id: "smilepay-ui-container",
            "data-orion-id": "smilepay-ui-container",
          },
        },
        [
          _c("smile-loading", {
            attrs: { active: _vm.isLoading, "data-orion-id": "smile-loading" },
          }),
          _vm._l(_vm.combinedFinanceTerms, function (cft) {
            return _c("PaymentOption", {
              key: _vm.getSmilePayKey(cft.term.months),
              ref: "smilepay",
              refInFor: true,
              attrs: {
                title: _vm.paymentOptionsTitlesEnum.SMILE_PAY,
                financeTerm: cft.term,
                discountedOption: cft.discountedTerm,
                price: _vm.financingPrice,
                productSKU: _vm.productSKU,
                currency: _vm.currency,
                country: _vm.country,
                region: _vm.region,
                targetDomain: _vm.targetDomain,
                discount: _vm.discount,
                paymentDescriptions: _vm.getSmilePayDescription(
                  cft.discountedTerm ? cft.discountedTerm : cft.term
                ),
                selected: _vm.isSmilePaySelected(
                  _vm.selectedOption,
                  cft.term.months
                ),
                "label-config": _vm.smilePayLabelConfig,
                "payment-text": _vm.smilePayPaymentText,
              },
              on: {
                "payment-option-selected": _vm.paymentOptionSelectedChanged,
                mounted: function ($event) {
                  _vm.optionMounted(_vm.getSmilePayKey(cft.term.months))
                },
              },
            })
          }),
          !_vm.isLoading
            ? _c("PaymentOption", {
                key: "singlepay",
                ref: "singlepay",
                attrs: {
                  title: _vm.paymentOptionsTitlesEnum.SINGLE_PAY,
                  price: _vm.price,
                  productSKU: _vm.productSKU,
                  currency: _vm.currency,
                  country: _vm.country,
                  discount: _vm.discount,
                  region: _vm.region,
                  targetDomain: _vm.targetDomain,
                  paymentDescriptions: _vm.singlePayPaymentDescriptions,
                  selected: "singlepay" === _vm.selectedOption,
                  "label-config": _vm.singlePayLabelConfig,
                  "payment-text": _vm.singlePayPaymentText,
                },
                on: {
                  "payment-option-selected": _vm.paymentOptionSelectedChanged,
                  mounted: function ($event) {
                    return _vm.optionMounted("singlepay")
                  },
                },
              })
            : _vm._e(),
          _vm.showDeferredPayment
            ? _c("PaymentOption", {
                key: "notPurchasing",
                ref: "notPurchasing",
                attrs: {
                  title: _vm.paymentOptionsTitlesEnum.PURCHASE_LATER,
                  price: _vm.price,
                  productSKU: _vm.productSKU,
                  currency: _vm.currency,
                  country: _vm.country,
                  region: _vm.region,
                  targetDomain: _vm.targetDomain,
                  paymentDescriptions: _vm.purchaseLaterPaymentDescriptions,
                  selected: "notPurchasing" === _vm.selectedOption,
                },
                on: {
                  "payment-option-selected": _vm.paymentOptionSelectedChanged,
                  mounted: function ($event) {
                    return _vm.optionMounted("notPurchasing")
                  },
                },
              })
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }