<template>
  <div class="smilepay-label-container" :style="containerStyles">
    <SvgIcon
      class="mr-3"
      :icon-paths="this.labelConfig.svg"
      :color="this.labelConfig.secondaryColor"
    />
    <p class="smilepay-label-text">{{ labelConfig.labelText }}</p>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue'

export default {
  name: 'SmilePayLabel',
  components: {
    SvgIcon,
  },
  props: {
    labelConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    containerStyles() {
      return {
        '--primary-color': this.labelConfig.primaryColor,
        '--secondary-color': this.labelConfig.secondaryColor,
      }
    },
  },
}
</script>

<style scoped>
.smilepay-label-container {
  background-color: var(--primary-color);
  padding: 10px 21px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.smilepay-label-text {
  color: var(--secondary-color);
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
  margin-right: 5px;
}
</style>
