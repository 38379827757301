var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        "smilepay-ui-card-content",
        "card card-content-padding",
        "justify-center",
        "mt-5",
        {
          "payment-selected": _vm.selected,
          "has-border": _vm.selected,
        },
      ],
      attrs: { "data-orion-id": `smilepay-ui-card-content-${_vm.optionId}` },
    },
    [
      _c("smile-media-card", {
        staticClass: "smilepay-ui-smile-card",
        attrs: {
          "data-orion-id": "smilepay-ui-smile-card",
          contentContainerCustomClass: "smilepay-ui-card-first-child pt-5 pb-5",
        },
        nativeOn: {
          click: function ($event) {
            return _vm.selectedPaymentPlan.apply(null, arguments)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "card-content",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "is-flex is-justify-content-space-between mb-14",
                  },
                  [
                    _vm.showLabel
                      ? _c("SmilePayLabel", {
                          staticClass: "smilepay-label",
                          attrs: { "label-config": _vm.labelConfig },
                        })
                      : _vm._e(),
                    _c("smile-radio", {
                      staticClass: "smilepay-radio",
                      attrs: {
                        "data-orion-id": `smilepay-ui-payment-option-type-${_vm.optionId}`,
                        "native-value": true,
                        value: _vm.selected,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "smilepay-title-price-wrapper is-flex is-justify-content-space-between mt-8",
                  },
                  [
                    _c("div", { staticClass: "smilepay-title-text" }, [
                      _vm._v(" " + _vm._s(_vm.title)),
                      _vm.financeTerm
                        ? _c("sup", { staticClass: "tm-superscript" }, [
                            _vm._v("TM"),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "price-container",
                        attrs: {
                          "data-orion-id": `smilepay-ui-payment-option-price-${_vm.optionId}`,
                        },
                      },
                      [
                        _vm.isDiscountValid
                          ? _c(
                              "div",
                              {
                                attrs: {
                                  "data-orion-id": `smilepay-ui-payment-option-discount-price-${_vm.optionId}`,
                                },
                              },
                              [
                                _c("b", [
                                  _vm._v(_vm._s(_vm.discountedPriceText)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            class: {
                              "discount-text": _vm.isDiscountValid,
                              "bold-text": !_vm.isDiscountValid,
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.priceText) + " ")]
                        ),
                      ]
                    ),
                  ]
                ),
                _c("hr", { staticClass: "smilepay-black-line" }),
                _vm.showLabel
                  ? _c("p", { staticClass: "smilepay-payment-text" }, [
                      _vm._v(_vm._s(_vm.paymentText)),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "smilepay-description-content" }, [
                  _c(
                    "ul",
                    _vm._l(_vm.paymentDescriptions, function (description) {
                      return _c(
                        "li",
                        {
                          key: description,
                          staticClass: "smilepay-description-item",
                          attrs: {
                            "data-orion-id": `smilepay-ui-payment-option-description-${_vm.optionId}`,
                          },
                        },
                        [
                          _c("smile-icon", {
                            attrs: {
                              icon: "fe fe-check-circle",
                              type: "is-primary",
                            },
                          }),
                          _c("p", {
                            staticClass: "smilepay-description-item-text",
                            domProps: { innerHTML: _vm._s(description) },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }